import { combineReducers } from 'redux';
import kycDocModalReducer from './kycDocModalReducer';
import kycDocReducer from './kycDocReducer';
import kycBVNModalReducer from './kycBVNModalReducer';
import kycBVNReducer from './kycBVNReducer';

export default combineReducers({
  kycDocModal: kycDocModalReducer,
  kycDoc: kycDocReducer,
  kycBVNModal: kycBVNModalReducer,
  kycBVN: kycBVNReducer
});
