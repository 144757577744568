import { combineReducers } from 'redux';
import customersReducer from './customersReducer';
import customerReducer from './customerReducer';
import consolidatedAccountsReducer from './consolidatedAccountsReducer';
import bankCardsReducer from './bankCardsReducer';
import bankAccountsReducer from './bankAccountsReducer';
import onboardingReducer from './onboardingReducer';
import investmentsReducer from './investmentsReducer';
import customerReferralsReducer from './customerReferralsReducer';
import getCBADumpReducer from './getCBADumpReducer';
import rejectCustomersAddrReducer from './rejectCustomersAddReducer';

export default combineReducers({
  customers: customersReducer,
  customer: customerReducer,
  consolidatedAccounts: consolidatedAccountsReducer,
  bankCards: bankCardsReducer,
  bankAccounts: bankAccountsReducer,
  onboarding: onboardingReducer,
  investments: investmentsReducer,
  referrals: customerReferralsReducer,
  cbaDump: getCBADumpReducer,
  getrejectedAddr: rejectCustomersAddrReducer
});
