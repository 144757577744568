const gateway_urls = {
  preLoginCheck(channel) {
    return `/SystemUtils/PreLoginCheck?channel=${channel}`;
  },
  healthCheck(channel) {
    return `/SystemUtils/HealthCheck?channel=${channel}`;
  },
  geolocation: '/GeoLocation/NigeriaLocations',
  banks: '/Miscellaneous/Banks'
};

const audit_urls = {
  getAudit: '/Audit/GetAll'
};
const auth_urls = {
  signIn: '/UserIdentity/Auth/SignIn',
  refreshToken: '/UserIdentity/Auth/RefreshToken',
  changePassword: '/UserIdentity/Auth/ChangePassword',
  forgotPassword: '/UserIdentity/Auth/PasswordPasswordInitiate',
  resetPassword: '/UserIdentity/Auth/PasswordResetComplete',
  resetInternalUserPassword: '/UserIdentity/Auth/ResetInternalUserPassword',
  getProfile: '/UserIdentity/Profile/GetProfile'
};

const profile_urls = {
  getProfile: '/UserIdentity/Profile/GetProfile',
  uploadImage: '/UserIdentity/Profile/UploadAvatar',
  updateProfile: '/UserIdentity/Profile/Update',
  deleteProfilePhoto: '/Customer/Customer/DeleteProfilePhoto',
  getSystemUsers: '/UserIdentity/Profile/GetAll',
  deleteSystemUser: '/UserIdentity/Profile/Delete',
  createSystemUser: '/UserIdentity/Profile/CreateInternalUser',
  systemUserLockStatus: '/UserIdentity/Profile/ChangeLockStatus'
};

const customer_urls = {
  getCustomers: '/Customer/Customer/GetAll',
  getCustomer: `/Customer/Customer/GetProfile`,
  updateCustomer: `/Customer/Customer/UpdateBioData`,
  deleteCustomer: `/Customer/Customer`,
  getAccountOfficers: '/Customer/Customer/GetAccountOfficers',
  changeAccountOfficer: '/Customer/Customer/ChangeAccountOfficer',
  getConsolidatedAccounts: '/Customer/Customer/GetConsolidatedAccounts',
  changeLockStatus: '/Customer/Customer/ChangeLockStatus',
  createCBADump: '/Customer/Onboarding/CreateCBADump',
  getCBADump: '/Customer/Onboarding/GetAllCBADump',
  changeCustomerStatus: '/Customer/Customer/ChangeType',
  deleteAddress: '/Customer/KYC/RejectAddress',
  getAddress: '/Customer/KYC/GetRejectedAddress',
  sendContract: '/Customer/CampusAmbassador/Create',
  changeStatus: '/Customer/CampusAmbassador/ChangeStatus'
};

const onboardingUrl = {
  getAll: '/Customer/Onboarding/GetAll'
};
const referral_urls = {
  getAllReferrals: '/Customer/Referral/GetAll',
  getCustomerReferrals: '/Customer/Referral/GetCustomerReferrals',
  GetReferredCustomers: '/Customer/Referral/GetReferredCustomers',
  GetCADetails: '/Customer/CampusAmbassador/GetDetails',
  GetCampaigns: '/Customer/Referral/GetCampaigns',
  ChangeCampaignStatus: '/Customer/Referral/ChangeCampaignStatus',
  UpdateCampaign: '/Customer/Referral/UpdateCampaign',
  getReferralStats: '/Customer/Referral/GetReferralStats'
};

const kyc_urls = {
  updateRiskRating: '/Customer/KYC/UpdateRiskRating',
  getRiskRatings: '/Customer/KYC/GetAllRiskRatings',
  getUserRiskRating: '/Customer/KYC/GetRiskRating',
  getBvnPhoto: '/Customer/KYC/GetBvnPhoto',
  approveOrRejectBVN: '/Customer/KYC/ApproveOrRejectBVN',
  approveOrRejectKYCDocument: '/Customer/KYC/ApproveOrRejectKYCDocument',
  getAllBVNRecord: '/Customer/KYC/GetAllBVNRecord',
  getKycChecklistReport: '/StatCenter/Reports/GetKycChecklistReport',
  getKycChecklistStats: '/StatCenter/Reports/GetKycChecklistStats',
  getProofOfAddressReport: '/StatCenter/Reports/GetProofOfAddressReport',
  getProofOfAddressStats: '/StatCenter/Reports/GetProofOfAddressStats',
  changeBVNStatus: '/Customer/KYC/ChangeBVNStatus'
};

const miscellaneous_urls = {
  setNoticeBoard: '/Customer/Miscellaneous/SetAppNoticeBoard',
  getNoticeBoard: '/Customer/Miscellaneous/GetAppNoticeBoard',
  getAllSupportRequests: '/Customer/Miscellaneous/GetSupportRequests'
};

const financial_urls = {
  getTransactionHistory: '/FinanceHub/Wallet/GetTransactionHistory',
  getCards: '/FinanceHub/BankAndCard/GetCards',
  getAccounts: '/FinanceHub/BankAndCard/GetAccounts',
  removeCard: '/FinanceHub/BankAndCard/RemoveCard',
  removeAccount: '/FinanceHub/BankAndCard/RemoveAccount',
  changeCardStatus: '/FinanceHub/BankAndCard/ChangeCardStatus',
  changeWalletStatus: '/FinanceHub/Wallet/ChangeStatus',
  getVirtualAccount: '/FinanceHub/BankAndCard/GetVirtualAccount',
  createVirtualAccount: '/FinanceHub/BankAndCard/CreateVirtualAccount',
  removeVirtualAccount: '/FinanceHub/BankAndCard/RemoveVirtualAccount',
  withdrawalDetails: '/FinanceHub/Wallet/GetWithdrawalDetails',
  getInternalBankAccounts: '/FinanceHub/BankAndCard/GetInternalBankAccounts',
  authorizeWithdrawal: '/FinanceHub/Wallet/AuthorizeWithdrawal',
  rejectWithdrawal: '/FinanceHub/Wallet/RejectWithdrawal'
};

const savings_urls = {
  getAll: '/Savings/Products/GetAll',
  getProduct: '/Savings/Products/GetProduct',
  updateProduct: '/Savings/Products/Update',
  getRegularSubscriptionDetail: '/Savings/Savings/GetRegularSubscriptionDetail',
  getTargetSubscriptions: '/Savings/Savings/GetTargetSubscriptions',
  getTargetSubscriptionDetail: '/Savings/Savings/GetTargetSubscriptionDetail',
  getFixedSubscriptions: '/Savings/Savings/GetFixedSubscriptions',
  getFixedSubscriptionDetail: '/Savings/Savings/GetFixedSubscriptionDetail',
  getSubscriptionSummary: '/Savings/Savings/GetSubscriptionSummary',
  getTransactionHistory: '/Savings/Savings/GetTransactionHistory',
  getTransactionDetails: '/Savings/Savings/GetTransactionDetails',
  changeSubscriptionStatus: '/Savings/Savings/ChangeSubscriptionStatus'
};

const investments_urls = {
  getAll: '/Investments/Products/GetAll',
  getProduct: '/Investments/Products/GetProduct',
  update: '/Investments/Products/Update',
  getInterestRate: '/Investments/Products/GetInterestRate',

  getSubscriptions: '/Investments/Portfolio/GetSubscriptions',
  getMFSubscriptionDetail: '/Investments/Portfolio/GetMutualFundSubscriptionDetail',
  getTreasuryBillSubscriptionDetail: '/Investments/Portfolio/GetTreasuryBillSubscriptionDetail',
  changeSubscriptionStatus: '/Investments/Portfolio/ChangeSubscriptionStatus',
  getTransactionHistory: '/Investments/Portfolio/GetTransactionHistory',
  getTransactionDetails: '/Investments/Portfolio/GetTransactionDetails',
  refreshSubscription: '/Investments/Portfolio/RefreshSubscription',
  updateTreasuryVolume: '/Investments/Products/UpdateTreasuryBillVolume'
};

const reports_url = {
  getTransactionCombinedReport: '/StatCenter/Reports/GetTransactionCombinedReport',
  getProductSubscriptionReport: '/StatCenter/Reports/GetProductSubscriptionReport',
  getGroupedSubscriptionReport: '/StatCenter/Subscriptions/GetGroupedSubscriptionReport',
  getSavingsReport: '/StatCenter/Subscriptions/GetSavingsReport',
  getSavingsStats: '/StatCenter/Subscriptions/GetSavingsStats',
  getGoalsReport: '/StatCenter/Subscriptions/GetGoalsReport',
  getGoalsStats: '/StatCenter/Subscriptions/GetGoalsStats',
  getFixedFundsReport: '/StatCenter/Subscriptions/GetFixedFundsReport',
  getFixedFundsStats: '/StatCenter/Subscriptions/GetFixedFundsStats',
  getInflowReport: '/StatCenter/Reports/getWalletinflowReport',
  getWithdrawalReport: '/StatCenter/Reports/GetWithdrawalReport',
  getPendingWithdrawalReport: '/StatCenter/Reports/GetPendingWithdrawalReport',
  getPortfolioTransactionStats: '/StatCenter/Reports/GetPortfolioTransactionStats'
};

const apiRoutes = {
  gateway: gateway_urls,
  auth: auth_urls,
  profile: profile_urls,
  customer: customer_urls,
  onboarding: onboardingUrl,
  kyc: kyc_urls,
  financial: financial_urls,
  savings: savings_urls,
  investments: investments_urls,
  reports: reports_url,
  audit: audit_urls,
  referral: referral_urls,
  miscellaneous: miscellaneous_urls
};

export default apiRoutes;
