import * as deleteAddressType from '../types/rejectCustomersAddTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  userId: null,
  succeeded: false,
  message: '',
  errors: null
};

const rejectCustomersAddrReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteAddressType.LOADING:
      return {
        ...state,
        loading: true
      };
    case deleteAddressType.GET_ADDRESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case deleteAddressType.DELETE_ADDRESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((customerAddress) => customerAddress.dataIndex !== action.payload)
      };

    default:
      return state;
  }
};

export default rejectCustomersAddrReducer;
